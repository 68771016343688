<template>
  <div ref="map" style="height: 100%; width: 100%">
    <div class="mapboxgl-control-container">
      <div class="mapboxgl-ctrl-top-left">
        <div>
          <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
            <select-map-layer @input="updateMapStyle" />
          </div>
        </div>
      </div>
      <div class="mapboxgl-ctrl-bottom-left">
        <div class="photo-station-ctrl">
          <div class="mapboxgl-ctrl mapboxgl-ctrl-group align-center slider-container">
            <v-slider
              class="photo-station-scale-slider mx-2 mt-2"
              min="0.5"
              max="5"
              thumb-label=""
              step="0.5"
              v-model="scale"
              label="Scale"
            ></v-slider>
          </div>
        </div>
      </div>
      <div class="mapboxgl-ctrl-bottom-right">
        <div>
          <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
            <slot name="control-bottom-right"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoStation from '../../services/photoStation';
import { isSatellite, parseGeoJSON } from '../../utils/map';
import Map from '../../services/map';
import center from '@turf/center';
import bbox from '@turf/bbox';
import SelectMapLayer from '../SelectMapLayer.vue';
import { geostyleParcel, geostyleParcelSatellite } from '../../utils/geostyles';
import config from '../../config';
export default {
  components: { SelectMapLayer },
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      }
    },
    parcelId: {
      type: String,
      default: null
    },
    parcelGeojson: {
      type: [String, Object],
      default: null
    }
  },
  data() {
    return {
      mapStyle: '',
      id: '',
      map: null,
      photoStation: new PhotoStation(),
      show: true,
      timeout: null,
      initialRender: false,
      scale: null
    };
  },
  watch: {
    scale: {
      handler: 'onScaleChange'
    },
    parcelGeojson: {
      handler: 'renderParcel'
    },
    images: {
      handler: 'renderPhotoStation'
    }
  },
  computed: {
    geojson() {
      return parseGeoJSON(this.parcelGeojson);
    }
  },
  mounted() {
    this.init();
    const scaleFactor = this.photoStation.getScaleFactor(this.geojson);
    this.scale = scaleFactor;
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      this.photoStation.setImages(this.images);
      this.photoStation.setGeoJSON(this.parcelGeojson);
    },
    init() {
      this.map = new Map({
        accessToken: config.mapboxToken,
        container: this.$refs.map,
        zoom: 17
      });
      this.map.onLoad(() => {
        this.renderParcel();
        this.renderPhotoStation();
      });
      this.initialRender = true;
    },
    onScaleChange(value) {
      if (!this.initialRender) return;
      if (value !== this.photoStation.scale) {
        this.renderPhotoStation();
      }
    },
    renderPhotoStation() {
      this.setData();
      if (!this.map.styleLoaded) return;
      this.photoStation.applyScaleFactor({ scale: this.scale, geojson: this.geojson });
      this.map.add('photo-station', 'GEOJSON', { geojson: this.photoStation.getGeoJSON() });
      this.map.enableHover('photo-station');
      this.map.map.on('click', 'photo-station-fill', e => {
        this.$emit('click', e.features[0].properties.id);
      });
    },
    renderParcel(fit = true) {
      this.setData();
      if (!this.map.styleLoaded) return;
      let geojson = this.photoStation.geojson;
      if (isSatellite(this.map.style)) {
        geojson = Map.applyGeostyle(geojson, { ...geostyleParcelSatellite, fillOpacity: 0.05 });
      } else {
        geojson = Map.applyGeostyle(geojson, geostyleParcel);
      }
      const ct = center(geojson);
      const boundary = bbox(geojson);
      this.map.add('parcel', 'GEOJSON', { geojson });
      if (fit) {
        this.map.map.setCenter(ct.geometry.coordinates);
        this.map.map.fitBounds(boundary, { maxZoom: 17 });
      }
    },
    updateMapStyle(value) {
      if (this.map) {
        this.map.setStyle(value);
        setTimeout(() => {
          this.mapStyle = value;
          this.renderParcel(false);
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss">
.photo-station-ctrl {
  .v-messages {
    display: none !important;
  }
  .slider-container {
    width: 300px;
    @media (max-width: 600px) {
      width: 200px;
    }
  }
}
</style>
