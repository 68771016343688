import { parseGeoJSON } from '../utils/map';
import { bbox, center, circle, combine, destination, distance, point } from '@turf/turf';
import Parcel from './parcel';
import Orientation from './orientation';
import { randomNumber } from '../utils';
import Map from './map';
import { geostyleParcel, geostylePhotoStation } from '../utils/geostyles';

const DEFAULT_CIRCLE_RADIUS = 20;
const DEFAULT_LINE_LENGTH = 20;

class PhotoStation {
  /**
   *
   * @param {Array<Object>} images
   */
  constructor({ images, radius, length } = {}) {
    this.orientation = new Orientation();
    this.images = images || [];
    this.parcel = new Parcel();
    this.radius = radius || DEFAULT_CIRCLE_RADIUS;
    this.length = length || DEFAULT_LINE_LENGTH;
    this.units = 'feet';
    this.onClickPhotoStation;
    this.geojson = null;
    this.gpsOnly = false
    this.layers = {
      photoStation: 'photo-station-fill'
    };
    this.scale = 1;
  }

  setRadius(radius) {
    this.radius = radius;
  }

  setGeoJSON(geojson) {
    if (!geojson) {
      return;
    }
    this.geojson = { ...parseGeoJSON(geojson), id: randomNumber() };
    this.geojson = Map.applyGeostyle(this.geojson, geostyleParcel);
  }

  setLength(length) {
    this.length = length;
  }

  applyScaleFactor({ scale, geojson } = {}) {
    if (!scale) {
      if (!geojson) {
        throw new Error('Geojson is required');
      }
      scale = this.getScaleFactor(geojson);
    }
    this.scale = scale;
    this.length = DEFAULT_LINE_LENGTH * scale;
    this.radius = DEFAULT_CIRCLE_RADIUS * scale;
  }

  setImages(images) {
    this.images = images || [];
  }

  // renderParcel() {
  //   let geojson = this.geojson;
  //   if (!geojson) return;
  //   geojson = Map.applyGeostyle(geojson, geostyleParcel);
  //   const ct = center(geojson);
  //   const boundary = bbox(geojson);
  //   this.map.add('parcel', 'GEOJSON', { geojson });
  //   this.map.enableHover('parcel');
  //   this.map.map.setCenter(ct.geometry.coordinates);
  //   setTimeout(() => {
  //     this.map.map.fitBounds(boundary, { padding: 40 });
  //   }, 100);
  // }

  /**
   *
   * @returns  {Array<object>}
   */
  filtered() {
    if (!this.geojson) throw new Error('Parcel Geosjon is required');
    return this.images.filter(img => {
      if (!Orientation.isValidOrientation(img?.orientation?.heading) && !this.gpsOnly) {
        return false;
      }
      const pts = [
        img?.geolocation?.lng || img.geolocation?.longitude || null,
        img?.geolocation?.lat || img.geolocation?.latitude || null
      ];
      try {
        const boundary = bbox(parseGeoJSON(this.geojson));
        const [dst1, dst2] = [
          distance(point(pts), [boundary[0], boundary[1]], { units: 'feet' }),
          distance(point(pts), [boundary[2], boundary[3]], { units: 'feet' })
        ];
        // console.log(dst1, dst2);
        // if (dst1 > 400 && dst2 > 400) return false;
        return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
      return pts[0] && pts[1];
    });
  }

  validatedData() {
    return this.filtered().map(item => {
      return {
        center: [item.lng || item.longitude, item.lat || item.latitude],
        heading: item?.orientation?.heading
      };
    });
  }

  getGeoJSON(style, steps) {
    const images = this.filtered();

    const geojson = {
      type: 'FeatureCollection',
      features: []
    };

    images.forEach(img => {
      const geo = img.geolocation;
      const center = [geo.lng || geo.longitude, geo.lat || geo.latitude];
      const options = { steps: steps || 50, units: this.units };

      const imgCircle = circle(center, this.radius, options);
      geojson.features.push(imgCircle);

      if (Orientation.isValidOrientation(img?.orientation?.heading)) {
        const bearing = this.orientation.convert(img.orientation?.heading);
        const startPoint = destination(center, this.radius, bearing, options);
        const endPoint = destination(startPoint, this.length, bearing, options);

        imgCircle.properties.id = img.id;
        imgCircle.id = randomNumber();
        const line = {
          type: 'Feature',
          properties: {
            id: img.id
          },
          geometry: {
            type: 'LineString',
            coordinates: [startPoint.geometry.coordinates, endPoint.geometry.coordinates]
          },
          id: randomNumber()
        };
        geojson.features.push(line);
      }
    });

    return Map.applyGeostyle(geojson, style || geostylePhotoStation);
  }

  setParcel(id) {
    if (!id) return;
    this.parcel.setId(parcelId);
    this.parcel.fetch().then(() => {
      this.setGeoJSON(this.parcel?.geojson);
    });
  }

  getScaleFactor(geojson) {
    const boundary = bbox(geojson);

    const distances = [
      distance([boundary[0], boundary[1]], [boundary[0], boundary[3]], { units: 'feet' }),
      distance([boundary[0], boundary[3]], [boundary[2], boundary[3]], { units: 'feet' })
    ];

    const d = Math.min(...distances);
    let scaleFactor = d / (32 * DEFAULT_CIRCLE_RADIUS);
    scaleFactor = Math.round(scaleFactor * 2) / 2;

    if (scaleFactor < 0.5) {
      scaleFactor = 0.5;
    }

    return scaleFactor;
  }
}

export default PhotoStation;
