<template>
  <div>
    <v-menu offset-x transition="slide-x-transition">
      <template #activator="{ on }">
        <button class="mapboxgl-ctrl-fullscreen" v-on="on">
          <v-icon size="20" style="margin-top: -3px" color="black">mdi-map</v-icon>
        </button>
      </template>
      <v-card>
        <div class="d-flex flex-wrap border-a">
          <div v-for="style in mapStyles" :key="style.value" class="pa-2">
            <div>
              <img
                :src="`/map-layers/${style.value}.png`"
                @click="internalValue = style.style"
                class="hover-image map-style-thumbnail"
                :class="internalValue === style.style ? 'img-border' : ''"
              />
              <div class="text-center thumbnail-text" style="margin-top: -2px; font-size: 14px">
                {{ style.text }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lazyValue: this.value || ''
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(value) {
        this.lazyValue = value;
        this.$emit('input', value);
      }
    },
    mapStyles() {
      const MAP_STYLE_PREFIX = 'mapbox://styles/mapbox';
      const style = {
        streets: `${MAP_STYLE_PREFIX}/streets-v11`,
        satellite: `${MAP_STYLE_PREFIX}/satellite-v9`,
        light: `${MAP_STYLE_PREFIX}/light-v10`,
        outdoors: `${MAP_STYLE_PREFIX}/outdoors-v11`,
        satelliteStreet: `${MAP_STYLE_PREFIX}/satellite-streets-v11`
      };
      let styles = [];
      styles.push({
        text: 'Light',
        value: 'light',
        style: style.light
      });
      styles.push({
        text: 'Outdoor',
        value: 'outdoor',
        style: style.outdoors
      });
      styles.push({
        text: 'Street',
        value: 'street',
        style: style.streets
      });
      styles.push({
        text: 'Hybrid',
        value: 'satelliteStreet',
        style: style.satelliteStreet
      });
      styles.push({
        text: 'Satellite',
        value: 'satellite',
        style: style.satellite
      });
      return styles;
    }
  },
  created() {
    if (!this.internalValue) {
      this.internalValue = this.mapStyles[2].style;
    }
  }
};
</script>

<style scoped lang="sass">
.hover-image
  border: 2px solid transparent
  border-radius: 6px
  &:hover
    border: 2px solid #1976d2

.img-border
  border: 2px solid #1976d2

.map-style-thumbnail
  height: 45px
  width: 55px

  @media screen and (max-width: 500px)
    height: 35px
    width: 40px

.thumbnail-text
  @media screen and (max-width: 500px)
    font-size: 11px !important
</style>
